<template>
  <div class="container mescroll-touch">
    <div class="header">
      <div class="searchBox" @click="toSearch()">
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
        <span class="searchInput">请输入关键字搜索</span>
      </div>
      <div class="header_title">
        <div class="titleItem" :class="{'titleItem_active': tcode == item.TCode}" v-for="item in columnList" :key="item.Code" @click="changeTitleMenu(item.TCode, item.Code)">
          <span>{{item.DisplayName}}</span>
        </div>
      </div>
    </div>

    <div class="mescrollBox">
      <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
        <div class="list" id="dataList">
          <div class="item" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
            <div class="item_img">
              <img class="item_cover" :src="item.Thumbnail" />
            </div>
            <div class="item_content">
              <span class="item_title">{{item.Title}}</span>
            </div>
          </div>
        </div>
      </mescroll-vue>
    </div> 
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  name: 'ProductMuseum',
  data() {
    return {
      columnList: [],
      tcode: '',
      columnCode: '',
      recommendList: [],
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
        isLock: false
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata"></p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        }
			}
    }
  },
  components: {
    MescrollVue
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
  },
  created() {
    if(this.code) {
      this.getColumn();
    }
  },
  methods: {
    // 获取栏目
    getColumn() {
      this.$axios.get('/Api/api/Web/Article/GetCode?code=' + this.code).then(res => {
        let data = res.Data;
        if(data.length > 0) {
          this.tcode = data[0].TCode;
          this.columnCode = data[0].Code;
          this.getRecommendList();
        } else {
          this.showStayTuned = true;
        }
        this.columnList = data;
      })
    },
    // 获取最新推荐列表数据
    getRecommendList() {
      this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
        SubjectTCode: this.tcode,
        ModuleType: 2,
        PageIndex: 1,
        PageSize: 2
      }).then((res) => {
        if(res.Data.List) {
          this.recommendList = res.Data.List;
        }
      }).catch((e) => {
      })
    },
    // 获取最新上线列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          SubjectTCode: this.tcode,
          ModuleType: 7,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = [];
          if(res.Data.List) {
            arr = res.Data.List;
          }
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    changeTitleMenu(tcode, code) {
      this.tcode = tcode;
      this.columnCode = code;
      this.list = [];
      this.getRecommendList();
      this.mescroll.resetUpScroll(); // 刷新列表数据
    },
    toSearch() {
      this.scrollTop = this.$refs.mescroll.$el.scrollTop;
      this.$router.push({
        path: 'search',
        query: {
          code: this.code
        }
      });
    },
    imgclick(UrlLink) {
      let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    },
    toList() {
      this.$router.push({
        path: 'recommendList',
        query: {
          tcode: this.tcode
        }
      });
    },
    toContentPage(Id, Type) {
      this.scrollTop = this.$refs.mescroll.$el.scrollTop;
      // this.$router.push({
      //   path: 'contentPage',
      //   query: {
      //     id: Id,
      //     type: Type
      //   }
      // });
      this.$router.push({
        path: 'productDetails',
        query: {
          id: Id
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-image: url('../../assets/images/header_bg2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F8F8F8;
  .header {
    padding: 15px 15px 10px;
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      height: 35px;
      line-height: 35px;
      border-radius: 35px;
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      padding: 0 15px;
      margin-bottom: 10px;
      .searchIcon {
        width: 15px;
        height: 15px;
      }
      .searchInput {
        flex: 1;
        margin-left: 8px;
        border: none;
        color: #999999;
      }
    }
    .header_title{
      display: flex;
      align-items: center;
      .titleItem {
        position: relative;
        padding: 0 10px;
        font-size: 15px;
        color: #FFFFFF;
        span {
          display: inline-block;
          width: 100%;
        }
      }
      .titleItem_active::after {
        content: "";
        position: absolute;
        left: 37%;
        width: 26%;
        height: 3px;
        border-radius: 3px;
        background-color: #FFFFFF;
        bottom: -7px;
      }
    }
  }
  .mescrollBox {
    position: fixed;
    width: 100%;
    top: 95px;
    bottom: 0;
    height: auto;
    border-radius: 12px 12px 0 0;
    background-color: #F8F8F8;
  }
  .mescroll {
    padding: 15px 15px 0;
    box-sizing: border-box;
  }
  .banner {
    margin-bottom: 15px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 0px;
    overflow: hidden;
    .bannerImg {
      width: 100%;
      display: block;
      border-radius: 10px;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      width: calc(calc(100% / 2) - 5px);
      border-radius: 6px;
      margin-bottom: 10px;
      background-color: #FFFFFF;
      overflow: hidden;
      .item_img {
        position: relative;
        .item_cover {
          display: block;
          width: 100%;
        }
        .playIcon {
          position: absolute;
          right: 12px;
          bottom: 12px;
          width: 30px;
          height: 30px;
        }
      }
      .item_content {
        padding: 5px 8px 8px;
        .item_title {
          height: 35px;
          font-size: 13px;
          line-height: 17px;
          color: #333333;
          overflow: hidden;
          text-overflow:ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
          word-wrap: break-word;
        }
        .item_time {
          font-size: 12px;
          color: #999999;
          line-height: 24px;
        }
        .item_label {
          display: flex;
          flex-wrap: wrap;
          span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            border-radius: 2px;
            padding: 0 6px;
            margin-right: 6px;
            margin-bottom: 5px;
            background-color: rgba(255, 73, 98, 0.15);
            font-size: 11px;
            color: #CA001B;
          }
        }
        .item_nums {
          display: flex;
          justify-content: flex-end;
          .nums {
            display: flex;
            align-items: center;
            .watchIcon {
              width: 14px;
              margin: 0 2px 0 6px;
            }
            .likeIcon {
              width: 11px;
              margin: 0 2px 0 6px;
            }
            span {
              font-size: 11px;
              color: #666666;
            }
          }
        }
      }
    }    
    .item:nth-child(even) {
      margin-left: 10px;
    }
  }
}
</style>